import React from "react"
import Layout from "../components/layout"

export default function Producer({ pageContext }) {
  const { data } = pageContext;
  return (
    <Layout>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <p> {data.name} </p>
      <p> {data.city} </p>
      <p> {data.email} </p>
      <p> {data.address} </p>
    </Layout>
  )
}
